import React, {useEffect, useState} from 'react';
import styles from "./GenderInput.module.css";

const GenderInput = ({value, onChange}) => {
    const [gender, setGender] = useState(value);

    const handleGenderChange = (event) => {
        const selectedGender = event.target.value;
        setGender(selectedGender);
        onChange?.(selectedGender);
    };

    useEffect(() => {
        setGender(value);
    }, [value]);

    const genders = [
        {label: 'М', code: 'M'},
        {label: 'Ж', code: 'F'}
    ];

    return (
        <div className={styles.genderContainer}>
            <label className={styles.label}>Пол</label>
            <div className={styles.genders}>
                {genders.map(({label, code}) => (
                    <div key={code} className={styles.genderOption}>
                        <label className={styles.genderLabel}>{label}</label>
                        <input
                            type="radio"
                            name="gender"
                            value={code}
                            checked={gender === code}
                            onChange={handleGenderChange}
                            className={styles.genderRadio}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};


export default GenderInput;
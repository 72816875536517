import React, {useEffect, useState} from 'react';
import styles from './ProfileDetailsPage.module.css';
import NavigationBar from "../common/NavigationBar";
import {useNavigate} from "react-router-dom";
import {getProfile, updateProfile} from "../../services/clientService";
import {handleRestError} from "../../services/restUtils";
import ServiceList from "../trainer/ServiceList";
import WideButton from "../common/WideButton";
import {currentRole, Roles} from "../../services/authService";
import TextInput from "../common/TextInput";
import DateInput from "../common/DateInput";
import GenderInput from "../common/GenderInput";
import TextAreaInput from "../common/TextAreaInput";
import {updateAbout, updateContact} from "../../services/trainerService";
import pages from "../../pages";
import {getSportCategories} from "../../services/lookupService";

const ProfilePage = () => {

    const navigate = useNavigate();

    const [profileDetails, setProfileDetails] = useState({});

    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [birthday, setBirthday] = useState(null);
    const [sex, setSex] = useState(null);

    const [phone, setPhone] = useState(null);
    const [tgUsername, setTgUsername] = useState(null);
    const [city, setCity] = useState(null);
    const [location, setLocation] = useState(null);

    const [about, setAbout] = useState(null);

    const [profileWasChanged, setProfileWasChanged] = useState(false);

    const [sportCategories, setSportCategories] = useState([]);

    useEffect(() => {
        getProfile()
            .then(response => {
                const data = response.data;
                setProfileDetails(data);

                const personal = data?.personal;
                if (personal) {
                    setFirstName(personal.firstName);
                    setLastName(personal.lastName);
                    setBirthday(personal.birthday);
                    setSex(personal.sex);
                }

                const contact = data?.contact;
                if (contact) {
                    setPhone(contact.phone);
                    setTgUsername(contact.tgUsername);
                    setCity(contact.city);
                    setLocation(contact.location);
                }

                setAbout(data?.about);

                setProfileWasChanged(false);

            })
            .catch(error => {
                handleRestError(error, navigate);
            });

    }, [navigate, profileWasChanged]);


    useEffect(() => {
        getSportCategories()
            .then(response => {
                setSportCategories(response);
            })
            .catch(error => {
                handleRestError(error, navigate);
            });
    }, []);


    const isPersDataChanged = () => {
        return !(profileDetails?.personal?.firstName === firstName
            && profileDetails?.personal?.lastName === lastName
            && profileDetails?.personal?.birthday === birthday
            && profileDetails?.personal?.sex === sex);
    }

    const handleEditPersData = () => {
        if (!isPersDataChanged()) {
            return;
        }
        updateProfile(firstName, lastName, sex, birthday)
            .then(_ => {
                setProfileWasChanged(true);
            })
            .catch(error => {
                handleRestError(error, navigate);
            });
    }

    const isContactsChanged = () => {
        return !(profileDetails?.contact?.phone === phone
            && profileDetails?.contact?.tgUsername === tgUsername
            && profileDetails?.contact?.city === city
            && profileDetails?.contact?.location === location);
    }

    const handleEditContacts = () => {
        if (!isContactsChanged()) {
            return;
        }
        updateContact(phone, city, location)
            .then(_ => {
                setProfileWasChanged(true);
            })
            .catch(error => {
                handleRestError(error, navigate);
            });
    }

    const isAboutChanged = () => {
        return !(profileDetails?.about === about);
    }

    const handleEditAboutTrainer = () => {
        if (!isAboutChanged()) {
            return;
        }
        updateAbout(about)
            .then(_ => {
                setProfileWasChanged(true);
            })
            .catch(error => {
                handleRestError(error, navigate);
            });
    }

    const handleEditSkills = () => {
        navigate(pages.skills);
    }

    const handleEditServices = () => {
        console.log('edit services');
    }

    function renderSelectedCategories() {
        const selectedMap = new Map();

        if (sportCategories.length === 0) return;

        sportCategories.forEach(category => {
            const selected = category.subCategories
                .filter(subCategory => profileDetails?.skills?.includes(subCategory.code))
                .map(subCategory => subCategory.name);

            if (selected.length > 0) {
                selectedMap.set(category.name, selected);
            }
        });

        return (
            <div className={styles.categoryContainer}>
                {Array.from(selectedMap.entries())
                    .sort(([a], [b]) => a.localeCompare(b))
                    .map(([categoryName, subCategories]) => (
                        <div key={categoryName} className={styles.subCategory}>
                            <label>
                                <span
                                    className={styles.subCategoryLabel}>{categoryName}</span>: {subCategories.join(', ')}
                            </label>
                        </div>
                    ))}
            </div>
        );
    }

    return (
        <div className={styles.MainContainer}>
            <h1 className={styles.ProfileTitle}></h1>
            <div className={styles.optionalBlocks}>
                {profileDetails ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Личные данные</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <div className={styles.whiteBoxContainer}>
                                <section className={styles.whiteBoxCard}>
                                    <TextInput id="lastNameInput"
                                               label="Фамилия"
                                               placeholder="Введите фамилию"
                                               value={lastName}
                                               onChange={e => setLastName(e.target.value)}
                                    />
                                    <TextInput id="firstNameInput"
                                               label="Имя"
                                               placeholder="Введите имя"
                                               value={firstName}
                                               onChange={e => setFirstName(e.target.value)}
                                    />
                                    <DateInput label="Дата рождения" value={birthday}
                                               onChange={e => setBirthday(e.target.value)}/>
                                    <GenderInput value={sex} onChange={e => setSex(e)}/>
                                </section>
                                {isPersDataChanged() ?
                                    <div className={styles.editButtonContainer}>
                                        <WideButton text="Сохранить" onClick={handleEditPersData}
                                                    transparent={true}/>
                                    </div>
                                    : null
                                }
                            </div>

                        </section>
                    </div>
                    : null
                }

                {profileDetails ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Контакты</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <div className={styles.whiteBoxContainer}>
                                <section className={styles.whiteBoxCard}>
                                    <TextInput id="locationInput"
                                               label="Мобильный телефон"
                                               type="tel"
                                               value={phone}
                                               placeholder="+7 (___) ___-__-__"
                                               onChange={e => setPhone(e.target.value)}
                                    />
                                    <TextInput id="tgUsernameInput"
                                               label="Telegram"
                                               value={tgUsername}
                                               onChange={e => setTgUsername(e.target.value)}
                                    />
                                    <TextInput id="cityInput"
                                               label="Город"
                                               value={city}
                                               onChange={e => setCity(e.target.value)}
                                    />
                                    <TextInput id="locationInput"
                                               label="Адрес"
                                               value={location}
                                               onChange={e => setLocation(e.target.value)}
                                    />
                                </section>
                                {isContactsChanged() ?
                                    <div className={styles.editButtonContainer}>
                                        <WideButton text="Сохранить" onClick={handleEditContacts}
                                                    transparent={true}/>
                                    </div>
                                    : null
                                }
                            </div>

                        </section>
                    </div>
                    : null
                }

                {currentRole() === Roles.TRAINER && profileDetails ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Обо мне</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <div className={styles.whiteBoxContainer}>
                                <section className={styles.whiteBoxCard}>
                                    <TextAreaInput id="aboutInput"
                                                   placeholder="Обо мне..."
                                                   value={about}
                                                   onChange={e => setAbout(e.target.value)}
                                    />
                                </section>
                                {isAboutChanged() ?
                                    <div className={styles.editButtonContainer}>
                                        <WideButton text="Сохранить" onClick={handleEditAboutTrainer}
                                                    transparent={true}/>
                                    </div>
                                    : null
                                }
                            </div>
                        </section>
                    </div>
                    : null
                }

                {currentRole() === Roles.TRAINER && profileDetails ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Специализация</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <div className={styles.whiteBoxContainer}>
                                <section className={styles.whiteBoxCard}>
                                    {renderSelectedCategories()}
                                </section>
                                <div className={styles.editButtonContainer}>
                                    <WideButton text="Редактировать" onClick={handleEditSkills} transparent={true}/>
                                </div>
                            </div>
                        </section>
                    </div>
                    : null
                }

                {currentRole() === Roles.TRAINER && profileDetails ?
                    <div className={styles.expandedBlock}>
                        <div className={styles.expandedBlockHeader}>
                            <h2 className={styles.expandedBlockTitle}>Услуги</h2>
                        </div>
                        <section className={styles.expandedBlockSection}>
                            <ServiceList services={profileDetails?.services}/>
                            <div className={styles.editButtonContainer}>
                                <WideButton text="Редактировать" onClick={handleEditServices} transparent={true}/>
                            </div>
                        </section>
                    </div>
                    : null
                }

            </div>

            <NavigationBar/>
        </div>
    );
};


export default ProfilePage;

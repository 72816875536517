import {retryableApiClient} from "./apiClient";

export const getSkills = async () => {
    return retryableApiClient.get(`/v1/trainer/skills`);
};

export const saveSkills = async (sportSubCategories) => {
    return retryableApiClient.put(
        `/v1/trainer/skills`,
        sportSubCategories
    );
};

export const updateContact = async (phone, city, address) => {
    return retryableApiClient.put(
        `/v1/trainer/contact`,
        {
            phone: phone,
            city: city,
            address: address,
        }
    );
};

export const updateAbout = async (about) => {
    return retryableApiClient.put(
        `/v1/trainer/about`,
        {
            about: about,
        }
    );
};

export const getWorkTime = async () => {
    return retryableApiClient.get(`/v1/trainer/work-time`);
}

export const updateWorkTime = async (workTime) => {
    return retryableApiClient.post(
        `/v1/trainer/work-time`,
        JSON.stringify(workTime)
    );
}
import {retryableApiClient} from "./apiClient";

export const getShortProfile = async () => {
    return retryableApiClient.get(`/v1/client/profile/short`);
};

export const getProfile = async () => {
    return retryableApiClient.get(`/v1/client/profile`);
};

export const updateProfile = async (firstName, lastName, sex, birthday) => {
    return retryableApiClient.put(
        `/v1/client/profile/personal`,
        {
            firstName: firstName,
            lastName: lastName,
            sex: sex,
            birthday: birthday,
        }
    );
};

/**
 * Ищет тренера по его ИД
 * @param id ИД тренера
 * @returns анкета тренера
 */
export const findTrainerById = async (id) => {
    return retryableApiClient.get(`/v1/client/trainer/${id}/questionary`);
};

/**
 * Возвращает список тренеров по указанной категории
 * @param categoryName название категории
 * @param pageNumber номер страницы (для пагинации)
 * @param pageSize размер страницы (для пагинации)
 * @returns список тренеров
 */
export const findByCategory = async (categoryName, pageNumber, pageSize) => {
    let url = `/v1/client/trainer/search?category=${categoryName}`;
    if (pageNumber && pageSize) {
        url += `&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return retryableApiClient.get(`${url}`);
};

export const findComments = async (id, pageNumber, pageSize) => {
    let url = `/v1/client/trainer/${id}/comment`;
    if (pageNumber && pageSize) {
        url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return retryableApiClient.get(`${url}`);
};

export const findFavoriteTrainers = async (pageNumber, pageSize) => {
    let url = `/v1/client/favorite/trainer`;
    if (pageNumber && pageSize) {
        url += `?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return retryableApiClient.get(`${url}`);
};

export const toggleFavoriteTrainers = async (trainerId, isFavorite) => {
    let url = `/v1/client/favorite/trainer`;
    return retryableApiClient.post(`${url}?trainerId=${trainerId}&isFavorite=${isFavorite}`);
};

export const findTrainerService = async (trainerId) => {
    let url = `/v1/client/trainer/service`;
    return retryableApiClient.get(`${url}?trainerId=${trainerId}`);
};

export const findTrainerFreeDates = async (trainerId) => {
    let url = `/v1/client/trainer/available-dates`;
    return retryableApiClient.get(`${url}?trainerId=${trainerId}`);
};

export const bookingTrainerService = async (trainerId, serviceId, selectedDate, timeRange) => {
    let url = `/v1/client/booking`;
    return retryableApiClient.post(
        `${url}?trainerId=${trainerId}`,
        {
            serviceId: serviceId,
            selectedDate: selectedDate,
            timeRange: timeRange,
        }
    );
};
import React from "react";
import styles from "./DateInput.module.css";


const DateInput = ({label, value, onChange}) => {

    return (
        <div className={styles.dateInputContainer}>
            <label className={styles.label}>{label}</label>
            <input
                type="date"
                className={styles.input}
                value={value}
                onChange={onChange}
            />
        </div>
    )
}

export default DateInput;
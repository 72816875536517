import {retryableApiClient} from "./apiClient";

export const getSportCategories = async () => {
    return retryableApiClient.get(`/v1/lookup/sport-categories`)
        .then(response => {
            return response.data.map(category => ({
                ...category,
                subCategories: category.subCategories.sort((a, b) => a.name.localeCompare(b.name))
            })).sort((a, b) => a.name.localeCompare(b.name));
        });
};

import React, {useState} from "react";
import styles from './TextInput.module.css'

const TextInput = ({id, type, label, placeholder, value, onChange}) => {

    const [isValid, setIsValid] = useState(true);

    const handleInputChange = (e) => {
        validateInput(e.target.value);
        onChange(e)
    };

    const validateInput = (value) => {
        setIsValid(value.trim() !== '')
    };

    return (
        <div className={styles.inputSection}>
            <label htmlFor={id} className={styles.label}>{label}</label>
            <input
                id={id}
                type={type ? type : "text"}
                className={`${styles.input} ${!isValid ? styles.error : ''}`}
                placeholder={placeholder}
                value={value}
                onChange={handleInputChange}
            />
        </div>
    );
};

export default TextInput;
import React, {useEffect, useState} from 'react';
import NavigationBar from "../common/NavigationBar";
import styles from "./SkillPage.module.css";
import {useNavigate} from "react-router-dom";
import {handleRestError} from "../../services/restUtils";
import {getSportCategories} from "../../services/lookupService";
import WideButton from "../common/WideButton";
import pages from "../../pages";
import {getSkills, saveSkills} from "../../services/trainerService";

const SkillPage = () => {

    const navigate = useNavigate();

    const [sportCategories, setSportCategories] = useState([]);
    const [selectedSubcategories, setSelectedSubcategories] = useState([]);

    useEffect(() => {
        getSportCategories()
            .then(response => {
                setSportCategories(response);
            })
            .catch(error => {
                handleRestError(error, navigate);
            });

        getSkills()
            .then(response => {
                setSelectedSubcategories(response.data);
            })
            .catch(error => {
                handleRestError(error, navigate);
            });
    }, []);

    const handleSelectSubcategory = (event) => {
        const subcategoryCode = event.target.value;
        console.log('Нажал на категорию: ' + subcategoryCode);

        setSelectedSubcategories((prevSelected) =>
            prevSelected.includes(subcategoryCode)
                ? prevSelected.filter(code => code !== subcategoryCode)
                : [...prevSelected, subcategoryCode]
        );
    }

    function renderCategory(category) {
        return <div className={styles.categoryContainer} key={category.code}>

            <div className={styles.categoryHeader}>
                <span>{category.name}</span>
            </div>
            <div className={styles.categoryBody}>
                {category.subCategories.map(({name, code}) => (
                    <div key={code} className={styles.subCategory}>
                        <input
                            className={styles.subCategoryRadio}
                            type="checkbox"
                            name="subcategories"
                            value={code}
                            checked={selectedSubcategories.includes(code)}
                            onChange={handleSelectSubcategory}
                        />
                        <label className={styles.subCategoryLabel}>{name}</label>
                    </div>
                ))}
            </div>
        </div>;
    }

    const handleSaveSkills = () => {
        saveSkills(selectedSubcategories)
            .then(_ => {
                navigate(pages.profileDetails);
            })
            .catch(error => {
                handleRestError(error, navigate);
            });
    }

    function renderSelectedCategories() {
        const selectedMap = new Map();

        if (sportCategories.length === 0) return;

        sportCategories.forEach(category => {
            const selected = category.subCategories
                .filter(subCategory => selectedSubcategories.includes(subCategory.code))
                .map(subCategory => subCategory.name);

            if (selected.length > 0) {
                selectedMap.set(category.name, selected);
            }
        });


        return (
            <div className={styles.categoryContainer}>
                <div className={styles.categoryBody}>
                    {Array.from(selectedMap.entries())
                        .sort(([a], [b]) => a.localeCompare(b))
                        .map(([categoryName, subCategories]) => (
                            <div key={categoryName} className={styles.subCategory}>
                                <label>
                                <span
                                    className={styles.selectedSubCategoryLabel}>{categoryName}</span>: {subCategories.join(', ')}
                                </label>
                            </div>
                        ))}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.skillPageContainer}>
            <h2 className={styles.title}>Специализация</h2>
            <div className={styles.categories}>
                {sportCategories.map(category => renderCategory(category))}
            </div>

            <label className={styles.expandedBlockTitle}>Выбрано</label>
            <div className={styles.categories}>
                {renderSelectedCategories()}
            </div>
            <div className={styles.saveButtonContainer}>
                <WideButton text="Сохранить" onClick={handleSaveSkills}/>
            </div>
            <NavigationBar className={styles.navBar}/>
        </div>
    );
};

export default SkillPage;